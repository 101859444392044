import * as React from 'react';
import {useEffect, useState} from 'react';
import {getScreenZonesOccupancies, getScreenInformation} from '../server';
import {ScreenInfoState, ZoneOccupancy} from '../types';
import {Spinner} from '@autopay.io/style';
import {ErrorScreen} from "../../common/components/ErrorScreen";
import {dataFetchIntervalInMs, LAST_SUCCESSFUL_DATA_FETCH, occupancyFetchIntervalInMs, twoHoursInMs} from "../constants";
import '@autopay.io/screen-style/lib/main.css';
import {runEveryMidnight, runTenSecondsAfterEveryFullHour} from "../../util/utils";
import {OccupancyScreen} from '@autopay.io/screen-style';

interface OccupancyScreenIndexProps {
    screenId: string;
}

export const OccupancyScreenIndex = ({screenId}: OccupancyScreenIndexProps) => {

    const [screenInfo, setScreenInfo] = useState<ScreenInfoState>(null);
    const [zoneOccupancies, setZoneOccupancies] = useState<ZoneOccupancy[] | null>(null);

    useEffect(() => {
        getScreenInfo(screenId)
        setInterval(() => getScreenInfo(screenId), dataFetchIntervalInMs)
        getOccupancyInfo(screenId)
        setInterval(() => getOccupancyInfo(screenId), occupancyFetchIntervalInMs)
        runTenSecondsAfterEveryFullHour(() => getScreenInfo(screenId))
        runEveryMidnight(() => location.reload())
    }, [screenId]);

    const getScreenInfo = (id: string) => {
        getScreenInformation(id).then((r) => {
            const lastSuccessfulDataFetch = localStorage.getItem(LAST_SUCCESSFUL_DATA_FETCH);
            const now = new Date().getTime();
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setScreenInfo({type: 'DATA', screen: r});
                localStorage.setItem(LAST_SUCCESSFUL_DATA_FETCH, Date.now().toString());
            } else if (r === 'NOT_FOUND') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Screen not found'});
            } else if (r === 'ERROR' && lastSuccessfulDataFetch !== null && now - Number(lastSuccessfulDataFetch) > twoHoursInMs) {
                setScreenInfo({type: 'ERROR', code: null, message: 'Data not found'});
            }
        });
    }

    const getOccupancyInfo = (id: string) => {
        getScreenZonesOccupancies(screenId).then((r) => {
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setZoneOccupancies(r);
            }
        })
    }

    if (screenInfo === null || zoneOccupancies === null) {
        return <Spinner size={'md'} />;
    } else if (screenInfo.type === 'ERROR') {
        return <ErrorScreen text={screenInfo.message}/>;
    } else {
        return <OccupancyScreen screenInfo={screenInfo.screen} zoneOccupancies={zoneOccupancies} />
    }
};
