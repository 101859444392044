import {ScreenInfo, ZoneOccupancy} from './types';
import {FetchResponse} from '@autopay.io/common/lib/network/fetcher';
import {apiMap} from "@autopay.io/common/lib/util";

export const getScreenInformation = (screenId: string): Promise<FetchResponse<ScreenInfo | 'ERROR' | 'NOT_FOUND'>> => {
    const url = `${apiMap.screen}/details/${screenId}`
    return fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return 'NOT_FOUND';
            }
            return 'ERROR';
        })
        .catch(() => 'ERROR')
};

export const getScreenZonesOccupancies = (screenId: string): Promise<FetchResponse<ZoneOccupancy[] | 'ERROR' | 'NOT_FOUND'>> => {
    const url = `${apiMap.screen}/occupancy/${screenId}`
    return fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return 'NOT_FOUND';
            }
            return 'ERROR';
        })
        .catch(() => 'ERROR')
};
