import {getEnvironment} from '@autopay.io/common/lib/util';

export const convertCurrencyCode = (currency: string) => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'NOK':
            return 'kr'
        case 'SEK':
            return 'kr'
        case 'PLN':
            return 'zł'
        case 'ISK':
            return 'kr'
    }
}
function getEnvironmentTag(): string {
    const environment = getEnvironment();
    if (environment === 'local') {
        return 'test';
    }
    return environment;
}
export const getFirebaseInstanceUrl = `https://autopay-${getEnvironmentTag()}-io-screen.europe-west1.firebasedatabase.app/`;

export const runTenSecondsAfterEveryFullHour = (callbackFn: () => void) => {
    const hour = 60 * 60 * 1000;
    const currentDate = new Date();
    const firstCall =  hour - ((currentDate.getMinutes() * 60 + currentDate.getSeconds()) - 10) * 1000 - currentDate.getMilliseconds();
    setTimeout(() => {
        callbackFn();
        setInterval(callbackFn, hour);
    }, firstCall);
};

export const runEveryMidnight = (callbackFn: () => void) => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24,0,0)
    const firstCall = midnight.getTime() - now.getTime();
    setTimeout(() => {
        callbackFn();
        setInterval(callbackFn, 24 * 60 * 60 * 1000);
    }, firstCall);
};
