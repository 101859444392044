import {FetchResponse} from '@autopay.io/common/lib/network/fetcher';
import {apiMap} from '@autopay.io/common/lib/util';
import {ScreenIdJson, ScreenTypeResponse} from './types';
import {EventInfo} from "./scenes/entryExit/types";

export const getScreenType = (screenId: string): Promise<ScreenTypeResponse> => {
    const url = `${apiMap.screen}/type/${screenId}`
    return fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json().then((json) => {
                    return {
                        type: 'DATA',
                        data: json
                    };
                    }
                )
            }
            if (response.status === 404) {
                return {
                    type: 'NOT_FOUND'
                }
            }
            return response.json().then((json) => {
                return {
                    type: 'ERROR',
                    code: response.status.toString(),
                    message: json.message
                }
            })
        })
};

export const isScreenOnline = (): Promise<boolean> => {
    return fetch("https://screen.autopay.io", {
        mode: 'no-cors'
    })
        .then(() => true)
        .catch(() => false)
}

export const getScreenIdByHash = (hash: string): Promise<FetchResponse<ScreenIdJson | 'ERROR' | 'NOT_FOUND'>> => {
    const url = `${apiMap.screen}/id/${hash}`
    return fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return 'NOT_FOUND';
            }
            return 'ERROR';
        })
        .catch(() => 'ERROR')
};

export const sendScreenEventLog = (event: EventInfo, deviceId: string, deviceTime: string) => {
    fetch(`${apiMap.screen}/event/received?screenId=${event.screenId}&deviceId=${deviceId}&deviceTime=${deviceTime}&displayScreen=${event.displayScreen}&eventId=${event.eventId}`)
};
