import * as React from 'react';
import {AioPage} from "@autopay.io/style";

interface ErrorScreenProps {
    text: string;
}

export const ErrorScreen = ({text}: ErrorScreenProps) => {
    return (
        <>
            <AioPage type="panel-page">
                <div className="error-screen-panel-container">
                    <h1>{text}</h1>
                </div>
            </AioPage>
        </>
    );
}
