import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {render} from 'react-dom';
import {Route, Router as ReactRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {PriceScreenIndex} from './scenes/price';
import {Switch} from 'react-router';
import '@autopay.io/style/autopay-io.css';
import './app.less';
import {EntryExitIndex} from "./scenes/entryExit";
import {getScreenIdByHash, getScreenType, isScreenOnline} from './server';
import {ErrorScreen} from './common/components/ErrorScreen';
import {Spinner} from '@autopay.io/style';
import {ScreenTypeResponse} from './types';
import {FetchResponse} from "@autopay.io/common/lib/network/fetcher";
import {OccupancyScreenIndex} from "./scenes/occupancy";

export const browserHistory = createBrowserHistory();

export const App = () => {
    const [screenId, setScreenId] = useState<string | null>(null);
    const [screenTypeResponse, setScreenTypeResponse] = useState<ScreenTypeResponse>(null);
    let screenTypeResponseRef = useRef<ScreenTypeResponse>(null)

    const searchParams = new URLSearchParams(browserHistory.location.search);
    const redirectedFromUrl = searchParams.get('redirect')

    useEffect(() => {
        if (screenId) {
            if (screenId.length === 6) {
                getScreenIdByHash(screenId).then((response) => {
                    if (response !== 'ERROR' && response !== 'NOT_FOUND') {
                        browserHistory.replace(response.id)
                    } else {
                        handleResponseError(response);
                    }
                });
            } else {
                getScreenType(screenId).then((response) => {
                    setScreenTypeResponse(response)
                    screenTypeResponseRef.current = response
                });
            }
        }
    }, [screenId]);

    useEffect(() => {
        setInterval(() => {
            if (redirectedFromUrl && (screenTypeResponseRef.current === null || screenTypeResponseRef.current.type !== 'DATA')) {
                const errorCode = (screenTypeResponseRef.current?.type === 'ERROR' && screenTypeResponseRef.current?.code !== null) ? screenTypeResponseRef.current?.code : ""
                let errorMessage = errorCode;
                if (screenTypeResponseRef.current == null) {
                    errorMessage += 'Failed to get response from screen type request';
                } else if (screenTypeResponseRef.current?.type === 'ERROR'){
                    errorMessage += screenTypeResponseRef.current?.message
                } else if (screenTypeResponseRef.current?.type === 'NOT_FOUND') {
                    errorMessage += 'Screen type not found by type request';
                }

                const message =  `&error=${encodeURIComponent(errorMessage)}`

                isScreenOnline().then((isOnline) => {
                    if (isOnline) {
                        window.location.href = decodeURI(redirectedFromUrl) + message
                    }
                })
            }
        }, 60000)
    },[])

    const handleResponseError = (response: FetchResponse<'ERROR' | 'NOT_FOUND'>) => {
        if (response === 'NOT_FOUND') {
            setScreenTypeResponse({type: 'ERROR', code: null, message: 'Screen not found'});
        } else if (response === 'ERROR') {
            setScreenTypeResponse({type: 'ERROR', code: null, message: 'Error on fetching screen information'});
        }
    }

    const renderScreen = (screenIdParam: string | undefined) => {
        if (screenIdParam) {
            setScreenId(screenIdParam)
        } else {
            window.location.href = location.pathname;
        }
        if (screenTypeResponse === null || !screenId) {
            return <Spinner size={'md'} />;
        } else if (screenTypeResponse.type === 'ERROR') {
            return <ErrorScreen text='Error on fetching screen information'/>;
        } else if (screenTypeResponse.type === 'NOT_FOUND') {
            return <ErrorScreen text='Screen not found'/>;
        } else {
            switch (screenTypeResponse.data.type) {
                case 'PRICE': return <PriceScreenIndex screenId={screenId}/>
                case 'ENTRY_EXIT': return <EntryExitIndex screenId={screenId}/>
                case 'OCCUPANCY': return <OccupancyScreenIndex screenId={screenId}/>
                default: return <PriceScreenIndex screenId={screenId}/>
            }
        }
    }

    return (
        <ReactRouter history={browserHistory}>
            <Switch>
                <Route path={'/:screenId'} render={({ match }) => (
                    renderScreen(match.params.screenId)
                )} />
            </Switch>
        </ReactRouter>
    );

};
render(<App />, document.getElementById('app'));
